<script>
/* eslint-disable no-unused-vars */
// TODO: Consider using a store instead of passing functions down to this component from parent
import FlowchartDragHandle from '@components/flowchart/flowchart-drag-handle'

export default {
  name: 'BaseFlowChartNode',
  components: { FlowchartDragHandle },
  props: {
    title: {
      type: String,
      required: true,
    },
    titlePlaceholder: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: true,
    },
    descriptionPlaceholder: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: 'primary',
    },
    width: {
      type: Number,
      default: 300,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Function,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
    edit: {
      type: Function,
      required: false,
      default: () => {},
    },
    node: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        title: this.title,
        description: this.description,
      },
    }
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        this.edit(oldValue, newValue)
      },
      deep: true,
    },
  },
}
</script>

<template lang="html">
  <v-card :width="`${width}px`">
    <v-card-title class="py-2">
      <v-row no-gutters>
        <v-col cols="auto">
          <v-icon :color="iconColor" left>{{ icon }}</v-icon>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.title"
            :placeholder="titlePlaceholder"
            :readonly="!isRemovable"
            solo
            dense
            flat
            maxlength="20"
            hide-details
          >
          </v-text-field>
        </v-col>
        <v-col cols="auto">
          <FlowchartDragHandle v-if="isDraggable">
            <v-btn small icon>
              <v-icon color="grey" small>mdi-drag-horizontal-variant</v-icon>
            </v-btn>
          </FlowchartDragHandle>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="py-2">
      <v-row no-gutters align="center">
        <v-col cols="10">
          <v-text-field
            v-model="form.description"
            :placeholder="descriptionPlaceholder"
            :readonly="!isRemovable"
            solo
            dense
            flat
            hide-details
            maxlength="50"
          >
          </v-text-field>
        </v-col>

        <v-spacer> </v-spacer>

        <v-col cols="auto">
          <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="add">
                <v-list-item-title>Add</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isRemovable" @click="remove">
                <v-list-item-title>Remove</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

import store from '@state/store'

const DEFAULT_VIEW = 'dashboard'

export default [
  {
    path: '/',
    component: () => lazyLoadView(import('@views/public/index.vue')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          // If the user is already logged in redirect to the home page instead
          store.getters['auth/loggedIn'] ? next({ name: DEFAULT_VIEW }) : next()
        } catch (error) {
          console.error(error)
          next()
        }
      },
    },
    children: [
      {
        path: '',
        name: 'public-home',
        component: () =>
          lazyLoadView(import('@/client/router/views/login/login.vue')),
      },
      {
        path: '/login',
        name: 'login',
        component: () =>
          lazyLoadView(import('@/client/router/views/login/login.vue')),
      },
      {
        path: '/signup',
        name: 'signup',
        component: () =>
          lazyLoadView(import('@/client/router/views/login/login.vue')),
        props: (route) => ({
          activeStep: 2,
          servicePlan: route.query.servicePlan,
        }),
      },
      {
        path: '/signup-with-invitation',
        name: 'signup-with-invitation',
        component: () =>
          lazyLoadView(import('@/client/router/views/login/login.vue')),
        props: (route) => ({
          activeStep: 2,
          invitationId: route.query.invitationId,
          companyId: route.query.companyId,
        }),
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () =>
          lazyLoadView(import('@views/forgot-password/forgot-password.vue')),
      },
    ],
  },
  {
    path: '/careers/:companySlug',
    name: 'careers',
    component: () =>
      lazyLoadView(
        import('@/client/router/views/job-listings/job-listings.vue')
      ),
    props: (route) => ({
      companySlug: route.params.companySlug,
    }),
  },
  {
    path: '/careers/:companySlug/job/:jobId',
    name: 'job-page',
    component: () =>
      lazyLoadView(import('@/client/router/views/job-page/job-page.vue')),
    props: (route) => ({
      companySlug: route.params.companySlug,
      jobId: route.params.jobId,
    }),
  },
  {
    path: '/job-listings/:companySlug',
    name: 'job-listings',
    component: () =>
      lazyLoadView(
        import('@/client/router/views/job-listings/job-listings.vue')
      ),
    props: (route) => ({
      companySlug: route.params.companySlug,
    }),
  },
  {
    path: '/',
    component: () => lazyLoadView(import('@views/public/index.vue')),
    children: [
      {
        path: '/session-expired',
        name: 'session-expired',
        meta: {
          async beforeResolve(routeTo, routeFrom, next) {
            await logOutAndRedirect(routeFrom, next)
          },
        },
        component: () => lazyLoadView(import('@views/session-expired.vue')),
      },
    ],
  },
  {
    path: '/app',
    component: () => lazyLoadView(import('@views/app/index.vue')),
    meta: {
      authRequired: true,
    },
    children: [
      {
        name: 'app-home',
        path: '',
        component: () =>
          lazyLoadView(import('@views/app/dashboard/dashboard.vue')),
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () =>
          lazyLoadView(
            import('@/client/router/views/app/dashboard/dashboard.vue')
          ),
      },
      {
        name: 'jobs',
        path: 'jobs',
        component: () =>
          lazyLoadView(
            import('@/client/router/views/app/dashboard/dashboard.vue')
          ),
      },
      {
        name: 'create-job',
        path: 'job',
        component: () =>
          lazyLoadView(
            import('@/client/router/views/app/create-job/create-job.vue')
          ),
      },
      {
        name: 'edit-job',
        path: 'jobs/:jobId',
        component: () =>
          lazyLoadView(
            import('@/client/router/views/app/edit-job/edit-job.vue')
          ),
        props: (route) => ({
          jobId: route.params.jobId,
          jobPublished:
            String(route.query.jobPublished).toLowerCase() === 'true',
        }),
      },
      {
        name: 'manage-job-candidates',
        path: 'jobs/:jobId/candidates',
        component: () =>
          lazyLoadView(
            import(
              '@/client/router/views/app/manage-job-candidates/manage-job-candidates.vue'
            )
          ),
        props: (route) => ({
          jobId: route.params.jobId,
        }),
      },
      {
        name: 'manage-job-candidate',
        path: 'jobs/:jobId/candidates/:candidateId',
        component: () =>
          lazyLoadView(
            import(
              '@/client/router/views/app/manage-job-candidate/manage-job-candidate.vue'
            )
          ),
        props: (route) => ({
          jobId: route.params.jobId,
          candidateId: route.params.candidateId,
        }),
      },
      {
        name: 'team-management',
        path: 'team-management',
        component: () =>
          lazyLoadView(
            import('@views/app/team-management/team-management.vue')
          ),
      },
      {
        name: 'employer-branding',
        path: 'employer-branding',
        component: () =>
          lazyLoadView(
            import('@views/app/employer-branding/employer-branding.vue')
          ),
      },
      {
        name: 'company',
        path: 'company',
        component: () =>
          lazyLoadView(import('@views/app/manage-company/manage-company.vue')),
      },
    ],
  },
  {
    path: '/app',
    component: () => lazyLoadView(import('@views/app/index.vue')),
    props: {
      hideNavigation: true,
    },
    meta: {
      authRequired: true,
    },
    children: [
      {
        name: 'onboarding',
        path: 'onboarding',
        component: () =>
          lazyLoadView(import('@views/app/onboarding/onboarding.vue')),
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        await logOutAndRedirect(routeFrom, next)
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

async function logOutAndRedirect(routeFrom, next) {
  try {
    await store.dispatch('auth/logOut')

    const authRequiredOnPreviousRoute = routeFrom.matched.some(
      (route) => route.meta.authRequired
    )

    // Navigate back to previous page, or home as a fallback
    next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
  } catch (error) {
    // TODO: Display error to user if encountered
    console.error(error)
  }
}

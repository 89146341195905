// Privileges
const GET_USERS = 'getUsers'
const MANAGE_USERS = 'manageUsers'
const MANAGE_COMPANY = 'manageCompany'
const MANAGE_JOBS = 'manageJobs'

// Roles
const roleEnums = {
  ADMIN: 'ADMIN',
  LIMITED_ADMIN: 'LIMITED_ADMIN',
}

const roles = Object.values(roleEnums)

// Roles and respective privileges
const roleRights = new Map(
  Object.entries({
    ADMIN: [GET_USERS, MANAGE_USERS, MANAGE_COMPANY, MANAGE_JOBS],
    LIMITED_ADMIN: [GET_USERS, MANAGE_USERS, MANAGE_COMPANY, MANAGE_JOBS],
  })
)

// Helpers
function canManageJobs(userRights) {
  return userRights.includes(MANAGE_JOBS)
}

module.exports = {
  GET_USERS,
  MANAGE_USERS,
  MANAGE_COMPANY,
  MANAGE_JOBS,
  ...roleEnums,
  roles,
  roleRights,
  canManageJobs,
}

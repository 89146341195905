import apiClient from '@/client/utils/server-api-client'

export const state = {
  isLoaded: false,
  jobs: [],
}

export const mutations = {
  SET_IS_LOADED(state, value) {
    state.isLoaded = value
  },
  SET_JOBS(state, data) {
    state.jobs = data
  },
}

export const actions = {
  async create({ rootState, commit, dispatch }, data) {
    const job = (
      await apiClient.post(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs`,
        data
      )
    ).data

    return job
  },
  async get({ rootState }, { jobId }) {
    const job = (
      await apiClient.get(
        `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${jobId}`
      )
    ).data

    return job
  },
  async fetchAll({ commit, state, rootState }, useCache = true) {
    if (state.isLoaded && useCache) return state.jobs

    let jobs

    try {
      jobs = (
        await apiClient.get(
          `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs`
        )
      ).data

      jobs.forEach((jobs) => {
        jobs.createdAt = new Date(jobs.createdAt)
        jobs.modifiedAt = new Date(jobs.modifiedAt)
      })
    } catch (error) {
      if (error.response.status !== 404) throw error

      jobs = []
    }

    commit('SET_JOBS', jobs)
    commit('SET_IS_LOADED', true)

    return jobs
  },
  async update({ rootState }, { id, data }) {
    return await apiClient.patch(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${id}`,
      data
    )
  },
  async deleteJob({ rootState }, { id }) {
    return await apiClient.delete(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${id}`
    )
  },
  async removeWatcher({ rootState }, { id, email }) {
    return await apiClient.patch(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${id}/watchers`,
      [
        {
          op: 'remove',
          value: email,
        },
      ]
    )
  },
  async addWatcher({ rootState }, { id, email }) {
    return await apiClient.patch(
      `/api/v1/companies/${rootState.auth.currentUser.companyId}/jobs/${id}/watchers`,
      [
        {
          op: 'add',
          value: email,
        },
      ]
    )
  },
}

import _ from 'lodash'
import { roleRights } from '@client/utils/roles'
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth'
import apiClient from '@/client/utils/server-api-client'

export const state = {
  isLoaded: false,
  currentUser: null,
  userPreferences: {
    trialExpirationWarningDisabled: false,
  },
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
  },
  UPDATE_USER_PREFERENCES(state, data) {
    state.userPreferences = _.merge(state.userPreferences, data)
  },
  SET_IS_LOADED(state, value) {
    state.isLoaded = value
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  userRights(state) {
    return roleRights.get(state.currentUser.role)
  },
}

export const actions = {
  // This is automatically run in `client/state/store.js` when the app starts, along with any other actions named `init` in other modules.
  init({ state, commit, dispatch }) {
    commit('SET_CURRENT_USER', getSavedState('auth.currentUser'))
    dispatch('validate')
  },

  async logIn({ dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    await signInWithEmailAndPassword(getAuth(), email, password)

    return dispatch('setCurrentUser')
  },

  // Logs out the current user.
  async logOut({ commit }) {
    return signOut(getAuth()).then(() => {
      // CAVEAT: Using method from vuex-extensions package to reset state
      commit('SET_CURRENT_USER', null)
      this.reset()
    })
  },

  async signUp({ commit }, data) {
    return apiClient.post('/api/v1/auth/sign-up', data)
  },

  async signUpWithInvitation({ commit }, data) {
    return apiClient.post('/api/v1/auth/sign-up-with-invitation', data)
  },

  async setCurrentUser({ state, commit, dispatch }) {
    const currentUser = getAuth().currentUser

    if (!currentUser || state.isLoaded) return

    const claims = (await currentUser.getIdTokenResult(true)).claims
    let company = {}

    if (currentUser.emailVerified && claims.companyId) {
      company = await dispatch(
        'company/fetchCompany',
        { companyId: claims.companyId },
        {
          root: true,
        }
      )
    }

    const user = buildCurrentUser(
      currentUser,
      company,
      claims.personId,
      claims.role
    )

    commit('SET_CURRENT_USER', user)
    commit('SET_IS_LOADED', user)

    return user
  },

  sendResetPasswordEmail({ commit }, { email }) {
    return sendPasswordResetEmail(getAuth(), email)
  },

  async sendEmailVerification({ commit }) {
    return apiClient.post('/api/v1/auth/email-verification')
  },

  // Validates current user
  validate({ state }) {
    return Promise.resolve(state.currentUser)
  },

  async updateUserPreferences({ commit }, data) {
    commit('UPDATE_USER_PREFERENCES', data)
  },
}

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function buildCurrentUser(user, company, personId, role) {
  return {
    id: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    displayName: user.displayName,
    isOnboarded:
      user.emailVerified && company && company.status !== 'ONBOARDING',
    companyId: company.id,
    personId,
    role,
  }
}
